import { Pipe, PipeTransform } from '@angular/core';
import { RouteExecutor } from '../../@core/application-router/route.executor';
import { ApplicationRouterService } from '../../@core/application-router/application-router.service';

@Pipe({
    name: 'pageIdFromLink',
})
export class PageIdFromLinkPipe extends RouteExecutor implements PipeTransform {
    constructor(applicationRouterService: ApplicationRouterService) {
        super(applicationRouterService);
    }

    hrefLink = '';

    transform(value: string): string {
        if (!value) {
            this.processLink(value);
            return this.hrefLink;
        }

        if (value.includes('https://') || value.includes('https://')) {
            //  window.open(value, '_blank');
            console.log('http inteercepred');

            return '';
        }

        this.processLink(value);
        return this.hrefLink;
    }

    executePageLink(
        pageId: string,
        query: { [p: string]: string | string[] },
        params?: any
    ) {
        this.hrefLink = pageId;
    }
}
