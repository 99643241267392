import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationRouterService } from './application-router.service';
import { CommonModule } from '@angular/common';
import { RouterPreloadStratergy } from './router-preload.service';
import { PaymentProcessingComponent } from 'src/app/payment-processing/payment-processing.component';

const appRoutes: Routes = [
    {
        path: 'membership',
        loadChildren: () =>
            import('../../do-membership/do-membership.module').then(
                m => m.DoMembershipModule
            ),
    },
    {
        path: 'about-store',
        loadChildren: () =>
            import('../../about-store/about-store.module').then(
                m => m.AboutStoreModule
            ),
    },
    {
        path: 'trendingOffers',
        loadChildren: () =>
            import('../../tranding-offer/tranding-offer.module').then(
                m => m.TrandingOfferModule
            ),
    },
    {
        path: 'pressAndMedia',
        loadChildren: () =>
            import(
                '../../press-and-media-page/press-and-media-page.module'
            ).then(m => m.PressAndMediaModule),
    },
    {
        path: 'uip',
        loadChildren: () =>
            import('../../personal-info-page/personal-info-page.module').then(
                m => m.PersonalInfoPageModule
            ),
    },
    {
        path: 'ump',
        loadChildren: () =>
            import(
                '../../account-page/user-membership-page/user-membership-page.module'
            ).then(m => m.UserMembershipPageModule),
    },
    {
        path: 'uap',
        loadChildren: () =>
            import('../../account-page/account-page.module').then(
                m => m.AccountPageModule
            ),
    },
    {
        path: 'olp',
        loadChildren: () =>
            import('../../placed-orders-page/placed-orders-page.module').then(
                m => m.PlacedOrdersPageModule
            ),
    },
    {
        path: 'cp',
        loadChildren: () =>
            import(
                '../../category-container-page/category-container-page.module'
            ).then(m => m.CategoryContainerPageModule),
    },
    {
        path: ':id/cp',
        loadChildren: () =>
            import(
                '../../category-container-page/category-container-page.module'
            ).then(m => m.CategoryContainerPageModule),
    },
    {
        path: 'osp',
        loadChildren: () =>
            import('../../order-summary-page/order-summary-page.module').then(
                m => m.OrderSummaryPageModule
            ),
    },
    {
        path: 'ap',
        loadChildren: () =>
            import('../../authentication-page/authentication-page.module').then(
                m => m.AuthenticationPageModule
            ),
    },
    {
        path: 'ap/verify',
        loadChildren: () =>
            import('../../verify-authentication-page/verify-otp.module').then(
                m => m.VerifyOtpModule
            ),
    },
    {
        path: ':id/:id1/lp',
        loadChildren: () =>
            import('../../listing-container/listing-page.module').then(
                m => m.ListingPageModule
            ),
    },
    {
        path: ':id1/lp',
        loadChildren: () =>
            import('../../listing-container/listing-page.module').then(
                m => m.ListingPageModule
            ),
    },
    {
        path: 'lp',
        loadChildren: () =>
            import('../../listing-container/listing-page.module').then(
                m => m.ListingPageModule
            ),
    },
    {
        path: 'map',
        loadChildren: () =>
            import('../../manage-address-page/manage-address-page.module').then(
                m => m.ManageAddressPageModule
            ),
    },
    // {
    //     path: 'manage-address',
    //     loadChildren: () =>
    //         import('../../manage-address-page/manage-address-page.module').then(
    //             m => m.ManageAddressPageModule
    //         ),
    // },
    {
        path: ':id/:id1/clp',
        loadChildren: () =>
            import('../../listing-container/listing-page.module').then(
                m => m.ListingPageModule
            ),
    },
    {
        path: ':id1/clp',
        loadChildren: () =>
            import('../../listing-container/listing-page.module').then(
                m => m.ListingPageModule
            ),
    },
    {
        path: 'clp',
        loadChildren: () =>
            import('../../listing-container/listing-page.module').then(
                m => m.ListingPageModule
            ),
    },
    {
        path: 'map',
        loadChildren: () =>
            import('../../manage-address-page/manage-address-page.module').then(
                m => m.ManageAddressPageModule
            ),
    },
    {
        path: ':id/dp',
        loadChildren: () =>
            import('../../detail-page/detail-page.module').then(
                m => m.DetailPageModule
            ),
    },
    {
        path: 'dp',
        loadChildren: () =>
            import('../../detail-page/detail-page.module').then(
                m => m.DetailPageModule
            ),
    },
    {
        path: 'psp',
        loadChildren: () =>
            import('../../payments-page/payments-page.module').then(
                m => m.PaymentsPageModule
            ),
    },
    {
        path: 'bp',
        loadChildren: () =>
            import('../../bag-page/bag-page.module').then(m => m.BagPageModule),
    },
    // {
    //     path: 'bag',
    //     loadChildren: () =>
    //         import('../../bag-page/bag-page.module').then(m => m.BagPageModule),
    // },
    {
        path: 'pip',
        loadChildren: () =>
            import('../../payments-input-page/payments-input-page.module').then(
                m => m.PaymentsInputPageModule
            ),
    },
    {
        path: 'aap',
        loadChildren: () =>
            import('../../add-address-page/add-address-page.module').then(
                m => m.AddAddressModule
            ),
    },
    // {
    //     path: 'add-address',
    //     loadChildren: () =>
    //         import('../../add-address-page/add-address-page.module').then(
    //             m => m.AddAddressModule
    //         ),
    // },
    {
        path: 'wlp',
        loadChildren: () =>
            import('../../wishlist-page/wishlist-page.module').then(
                m => m.WishlistPageModule
            ),
    },
    {
        path: ':id/rfp',
        loadChildren: () =>
            import('../../req-filters-page/req-filters-page.module').then(
                m => m.ReqFiltersPageModule
            ),
    },
    {
        path: 'rfp',
        loadChildren: () =>
            import('../../req-filters-page/req-filters-page.module').then(
                m => m.ReqFiltersPageModule
            ),
    },
    {
        path: 'gp',
        loadChildren: () =>
            import('../../generic-page/generic-page.module').then(
                m => m.GenericPageModule
            ),
    },
    {
        path: 'np',
        loadChildren: () =>
            import('../../notifications-page/notifications-page.module').then(
                m => m.NotificationsPageModule
            ),
    },
    {
        path: 'hcp',
        loadChildren: () =>
            import('../../help-center-page-v2/help-center-page-v2.module').then(
                m => m.HelpCenterPageV2Module
            ),
    },
    {
        path: 'policy-detail',
        loadChildren: () =>
            import('../../policy-details/policy-details.module').then(
                m => m.PolicyDetailsModule
            ),
    },
    {
        path: 'hclp',
        loadChildren: () =>
            import('../../help-center-page-v2/help-center-page-v2.module').then(
                m => m.HelpCenterPageV2Module
            ),
    },
    {
        path: 'hcdp',
        loadChildren: () =>
            import(
                '../../help-center-detail-page/help-center-detail-page.module'
            ).then(m => m.HelpCenterListingPageModule),
    },
    {
        path: 'op',
        loadChildren: () =>
            import('../../offer-page/offer-page.module').then(
                m => m.OfferPageModule
            ),
    },
    {
        path: 'coupons',
        loadChildren: () =>
            import('../../coupons/coupons.module').then(m => m.CouponsModule),
    },
    // {
    //     path: 'offer-coupon',
    //     loadChildren: () =>
    //         import('../../offer-page/offer-page.module').then(
    //             m => m.OfferPageModule
    //         ),
    // },
    {
        path: 'csp',
        loadChildren: () =>
            import(
                '../../checkout-summary-page/checkout-summary-page.module'
            ).then(m => m.CheckoutSummaryPageModule),
    },
    {
        path: 'lfp',
        loadChildren: () =>
            import('../../listing-filter-page/listing-filter-page.module').then(
                m => m.ListingFilterPageModule
            ),
    },
    {
        path: 'clfp',
        loadChildren: () =>
            import('../../listing-filter-page/listing-filter-page.module').then(
                m => m.ListingFilterPageModule
            ),
    },
    {
        path: 'odp',
        loadChildren: () =>
            import('../../order-detail-page/order-detail-page.module').then(
                m => m.OrderDetailPageModule
            ),
    },
    {
        path: 'escalationForm',
        loadChildren: () =>
            import('../../escalation-form/escalation-form-page.module').then(
                m => m.EscalationFormPageModule
            ),
    },
    {
        path: 'sfp',
        loadChildren: () =>
            import('../../support-form-page/support-form-page.module').then(
                m => m.SupportFormPagemodule
            ),
    },
    {
        path: 'stripe',
        loadChildren: () =>
            import('../../stripe/stripe.module').then(
                m => m.StripeModuleComponent
            ),
    },
    {
        path: 'top',
        loadChildren: () =>
            import('../../track-order-page/track-order-page.module').then(
                m => m.TrackOrderPageModule
            ),
    },
    {
        path: 'sp',
        loadChildren: () =>
            import('../../search-page/search-page.module').then(
                m => m.SearchPageModule
            ),
    },
    // {
    //     path: 'search',
    //     loadChildren: () =>
    //         import('../../search-page/search-page.module').then(
    //             m => m.SearchPageModule
    //         ),
    // },
    {
        path: 'footer',
        loadChildren: () =>
            import('../../footer/footer-page.module').then(
                m => m.FooterPageModule
            ),
    },

    {
        path: 'toup',
        loadChildren: () =>
            import('../../terms-of-use/terms-of-use.module').then(
                m => m.TermsOfUseModule
            ),
    },

    {
        path: 'glp',
        loadChildren: () =>
            import(
                '../../generic-listing-page/generic-listing-page.module'
            ).then(m => m.GenericListingPageModule),
    },
    {
        path: '',
        loadChildren: () =>
            import('../../home-page/home-page.module').then(
                m => m.HomePageModule
            ),
    },
    {
        path: 'hp',
        redirectTo: '',
        pathMatch: 'full',
    },
    {
        path: 'aup',
        loadChildren: () =>
            import('../../about-us/about-us.module').then(m => m.AboutUsModule),
    },
    {
        path: 'careers',
        loadChildren: () =>
            import('../../careers-page/careers-page.module').then(
                m => m.CareersPageModule
            ),
    },
    {
        path: 'paypal/processing',
        loadChildren: () =>
            import('../../paypal-order/paypal-order.module').then(
                m => m.PaypalOrderModule
            ),
    },
    {
        path: 'corporate-gifting',
        loadChildren: () =>
            import('../../corporate-page/corporate-page.module').then(
                m => m.CorporatePageModule
            ),
    },
    {
        path: 'success-popup',
        loadChildren: () =>
            import('../../success-popup/success-popup.module').then(
                m => m.SuccessPopupModule
            ),
    },
    {
        path: 'giftwrap/info',
        loadChildren: () =>
            import(
                '../../detail-page/gift-wrap-popup/gift-wrap-popup.module'
            ).then(m => m.GiftWrapPopupModule),
    },
    {
        path: 'giftwrap/customize',
        loadChildren: () =>
            import(
                '../../@shared-except-main/offer-coupon/giftwrap-customize-popup/giftwrap-customize-popup.module'
            ).then(m => m.GiftWrapCustomizePopupModule),
    },
    {
        path: 'email/verify',
        loadChildren: () =>
            import('../../email-verify-page/email-verify.module').then(
                m => m.EmailVerifyModule
            ),
    },
    {
        path: 'collection',
        loadChildren: () =>
            import('../../collection/collection.module').then(
                m => m.CollectionModule
            ),
    },
    {
        path: 'hp-popup-login',
        loadChildren: () =>
            import('../../first-login-auth/first-login-auth.module').then(
                m => m.FirstLoginAuthModule
            ),
    },
    {
        path: 'hp-popup-offer',
        loadChildren: () =>
            import('../../first-login-auth/first-login-auth.module').then(
                m => m.FirstLoginAuthModule
            ),
    },

    // Static Routes
    {
        path: 'designer-cases/:id1/:id2',
        loadChildren: () =>
            import(
                '../../legacy-static-route-hack/legacy-container.module'
            ).then(m => m.LegacyContainerModule),
    },
    {
        path: 'collection',
        loadChildren: () =>
            import('../../collection/collection.module').then(
                m => m.CollectionModule
            ),
    },
    {
        path: 'watch-accessories/:id1/:id2',
        loadChildren: () =>
            import(
                '../../legacy-static-route-hack/legacy-container.module'
            ).then(m => m.LegacyContainerModule),
    },
    {
        path: 'mobile-accessories/:id1/:id2',
        loadChildren: () =>
            import(
                '../../legacy-static-route-hack/legacy-container.module'
            ).then(m => m.LegacyContainerModule),
    },
    {
        path: 'mobile-accessories/:id1/:id2/:id3',
        loadChildren: () =>
            import(
                '../../legacy-static-route-hack/legacy-container.module'
            ).then(m => m.LegacyContainerModule),
    },
    {
        path: 'bags-and-sleeves/:id1/:id2',
        loadChildren: () =>
            import(
                '../../legacy-static-route-hack/legacy-container.module'
            ).then(m => m.LegacyContainerModule),
    },
    {
        path: 'designer-collections/:id1/:id2',
        loadChildren: () =>
            import(
                '../../legacy-static-route-hack/legacy-container.module'
            ).then(m => m.LegacyContainerModule),
    },
    {
        path: 'travel/:id1/:id2',
        loadChildren: () =>
            import(
                '../../legacy-static-route-hack/legacy-container.module'
            ).then(m => m.LegacyContainerModule),
    },
    {
        path: 'designer-cases',
        loadChildren: () =>
            import(
                '../../category-container-static-page/category-container-page.module'
            ).then(m => m.CategoryContainerStaticPageModule),
    },
    {
        path: 'oop',
        loadChildren: () =>
            import(
                '../../order-operation-page/order-operation-page.module'
            ).then(m => m.OrderOperationPageModule),
    },
    {
        path: 'oosp',
        loadChildren: () =>
            import(
                '../../order-operation-success-page/order-operation-success-page.module'
            ).then(m => m.OrderOperationSuccessPageModule),
    },
    {
        path: 'o/:id',
        loadChildren: () =>
            import('../../seo-page/seo-page.module').then(m => m.SeoPageModule),
    },
    {
        path: 'privacy-and-security-policy',
        loadChildren: () =>
            import('../../static-generic-page/static-generic-page.module').then(
                m => m.StaticGenericPageModule
            ),
    },
    {
        path: 'about-us',
        loadChildren: () =>
            import('../../about-us/about-us.module').then(m => m.AboutUsModule),
    },
    {
        path: 'terms-of-use',
        loadChildren: () =>
            import('../../terms-of-use/terms-of-use.module').then(
                m => m.TermsOfUseModule
            ),
    },
    {
        path: 'warranty-policy',
        loadChildren: () =>
            import('../../static-generic-page/static-generic-page.module').then(
                m => m.StaticGenericPageModule
            ),
    },
    {
        path: 'shipping-policy',
        loadChildren: () =>
            import('../../static-generic-page/static-generic-page.module').then(
                m => m.StaticGenericPageModule
            ),
    },
    {
        path: 'cancellation-policy',
        loadChildren: () =>
            import('../../static-generic-page/static-generic-page.module').then(
                m => m.StaticGenericPageModule
            ),
    },

    {
        path: 'return-replacement-and-refund-policy',
        loadChildren: () =>
            import('../../static-generic-page/static-generic-page.module').then(
                m => m.StaticGenericPageModule
            ),
    },

    {
        path: 'privacy-policy',
        loadChildren: () =>
            import('../../static-generic-page/static-generic-page.module').then(
                m => m.StaticGenericPageModule
            ),
    },
    {
        path: 'contact-us',
        loadChildren: () =>
            import('../../static-generic-page/static-generic-page.module').then(
                m => m.StaticGenericPageModule
            ),
    },
    {
        path: 'payment-shipping-returns',
        loadChildren: () =>
            import('../../static-generic-page/static-generic-page.module').then(
                m => m.StaticGenericPageModule
            ),
    },
    {
        path: 'orp',
        loadChildren: () =>
            import('../../order-rating-page/order-rating-page.module').then(
                m => m.OrderRatingPageModule
            ),
    },
    {
        path: 'payment-processing',
        component: PaymentProcessingComponent,
    },
    {
        path: 'orsp',
        loadChildren: () =>
            import(
                '../../order-operation-success-page/order-operation-success-page.module'
            ).then(m => m.OrderOperationSuccessPageModule),
    },
    {
        path: 'gifting',
        loadChildren: () =>
            import('../../gifting-page/gifting-page.module').then(
                m => m.GiftingPageModule
            ),
    },
    {
        path: 'rewards',
        loadChildren: () =>
            import('../../nector-rewards/nector-rewards.module').then(
                m => m.NectorRewardsPageModule
            ),
    },
    {
        path: '**',
        loadChildren: () =>
            import('../../page-not-found/page-not-found.module').then(
                m => m.PageNotFoundModule
            ),
    },
];

@NgModule({
    providers: [ApplicationRouterService],
    declarations: [],
    imports: [
        RouterModule.forRoot(appRoutes, {
            enableTracing: false,
            initialNavigation: 'enabledNonBlocking',
            urlUpdateStrategy: 'eager',
            scrollPositionRestoration: 'enabled',
            onSameUrlNavigation: 'ignore',
            relativeLinkResolution: 'legacy',
            preloadingStrategy: RouterPreloadStratergy,
        }),
        CommonModule,
    ],
    exports: [RouterModule],
})
export class ApplicationRouterModule {}
