<!-- dropdown-v1 -->

<!-- <div
    fxLayout="row"
    fxLayoutAlign="space-between"
    fxLayoutGap="24px"
    style="
        padding-left: 34px;
        border-color: #f7f7f7;
        border-top-width: 2px;
        border-top-style: solid;
    "
>
    <app-dropdown-shimmer
        *ngIf="ghosts.length > 0"
        [ghosts]="ghosts"
        class="dropdown-column-shimmer"
    ></app-dropdown-shimmer>
    <div
        *ngFor="let feed of data?.feed; let i = index"
        fxLayout="column"
        fxLayoutAlign="start"
        class="dropdown-column"
        [ngStyle]="{
            'border-width':
                data?.feed!![i + 1]?.layout === 'image' ||
                data?.feed!![i + 1]?.layout === '2-col'
                    ? '0 0 0 0'
                    : '0 0.5px 0 0'
        }"
        fxFlex="{{ feedBasedFlex[feed?.layout] || '1 1 auto' }}"
    >
        <a
            (click)="processLink(feed?.link); itemClicked('true')"
            class="anchor"
            [routerLink]="[feed?.link | pageIdFromLink]"
            [queryParams]="feed?.link | queryParamFromLink"
        >
            <img
                [ngStyle]="{ marginBottom: feed?.icon ? '0' : '0' }"
                *ngIf="feed?.icon"
                class="dropDown-icon"
                src="{{ feed?.icon }}"
                appImageCDN
            />
            <h1
                *ngIf="feed?.title"
                [ngStyle]="{ paddingLeft: feed?.icon ? '11px' : '0' }"
                class="dropdown-column-content dropdown-column-content-heading"
            >
                <var style="font-style: normal; margin: 0">
                    {{ feed?.title?.text }}
                    <sup
                        *ngIf="feed?.newLaunch"
                        style="color: #c21122; font-size: 12px"
                    >
                        New
                    </sup>
                </var>
            </h1>
        </a>
        1-column
        <div
            *ngIf="feed?.layout === '1-col' || feed?.layout === '1-col-bold'"
            class="dropdown-column-content"
            fxLayout="column"
            [ngStyle]="{ marginLeft: feed?.icon ? '11px' : '0' }"
        >
            <div *ngFor="let subCategory of feed?.feed" fxLayout="column">
                <a
                    (click)="
                        processLink(subCategory?.link); itemClicked('true')
                    "
                    class="anchor"
                    [routerLink]="[subCategory?.link | pageIdFromLink]"
                    [queryParams]="subCategory?.link | queryParamFromLink"
                >
                    <h2
                        *ngIf="subCategory.title"
                        class="dropdown-column-content-subheading"
                    >
                        <var style="font-style: normal"
                            >{{ subCategory?.title?.text }}
                            <sup
                                *ngIf="subCategory?.newLaunch"
                                style="color: #c21122; font-size: 12px"
                                >New</sup
                            ></var
                        >
                    </h2>
                </a>
                <div [ngSwitch]="feed?.layout">
                    <ng-container *ngSwitchCase="'1-col'">
                        <a
                            (click)="
                                processLink(subItem?.link); itemClicked('true')
                            "
                            class="anchor"
                            *ngFor="let subItem of subCategory?.feed"
                            [routerLink]="[subItem?.link | pageIdFromLink]"
                            [queryParams]="subItem?.link | queryParamFromLink"
                        >
                            <p class="dropdown-column-content-item">
                                <var>
                                    {{ subItem?.title?.text }}
                                    <sup
                                        *ngIf="subItem?.newLaunch"
                                        style="color: #c21122; font-size: 12px"
                                        >New</sup
                                    ></var
                                >
                            </p>
                        </a>
                    </ng-container>
                    <ng-container *ngSwitchCase="'1-col-bold'">
                        <a
                            (click)="
                                processLink(subItem?.link); itemClicked('true')
                            "
                            class="anchor"
                            *ngFor="let subItem of subCategory?.feed"
                            [routerLink]="[subItem?.link | pageIdFromLink]"
                            [queryParams]="subItem?.link | queryParamFromLink"
                        >
                            <h1
                                class="
                                    dropdown-column-content-heading
                                    dropdown-column-content-heading-list
                                "
                            >
                                <var style="font-style: normal; margin: 0"
                                    >{{ subItem?.title.text
                                    }}<sup
                                        *ngIf="subItem?.newLaunch"
                                        style="
                                            color: #c21122;
                                            font-size: 12px;
                                            font-family: 'Roboto Regular',
                                                sans-serif;
                                            padding-left: 4px;
                                        "
                                        >New</sup
                                    ></var
                                >
                            </h1>
                        </a>
                    </ng-container>
                </div>
            </div>
        </div>
        single column
        <div
            *ngIf="feed?.layout === '1-col-cases'"
            class="dropdown-column-content"
            fxLayout="row"
            fxLayoutGap="20px"
        >
            <div
                *ngFor="let subCategory of feed?.feed"
                fxLayout="column"
                fxFlex="0 0 100%"
            >
                <a
                    (click)="
                        processLink(subCategory?.link); itemClicked('true')
                    "
                    class="anchor"
                    [routerLink]="[subCategory?.link | pageIdFromLink]"
                    [queryParams]="subCategory?.link | queryParamFromLink"
                >
                    <h2 class="dropdown-column-content-subheading">
                        <var style="font-style: normal; margin: 0"
                            >{{ subCategory?.title?.text
                            }}<sup
                                *ngIf="subCategory?.newLaunch"
                                style="color: #c21122; font-size: 12px"
                                >New</sup
                            ></var
                        >
                    </h2>
                </a>
                <a
                    class="anchor"
                    *ngFor="let subItem of subCategory?.feed"
                    (click)="processLink(subItem?.link); itemClicked('true')"
                    [routerLink]="[subItem?.link | pageIdFromLink]"
                    [queryParams]="subItem?.link | queryParamFromLink"
                >
                    <p
                        [ngClass]="{
                            'footer-title-bold': subItem?.bold === true,
                            'footer-title-uline': subItem?.underline === true
                        }"
                        class="dropdown-column-content-item"
                        [ngStyle]="{ color: subItem.title.color }"
                    >
                        <var style="font-style: normal; margin: 0"
                            >{{ subItem?.title?.text
                            }}<sup
                                *ngIf="subItem?.newLaunch"
                                style="color: #c21122; font-size: 12px"
                                >New</sup
                            ></var
                        >
                    </p>
                </a>
            </div>
        </div>
        2 column
        <div
            *ngIf="feed?.layout === '2-col'"
            class="dropdown-column-content"
            fxLayout="row wrap"
            fxLayoutGap="20px"
            style="padding-top: 12px"
        >
            <div
                *ngFor="let subCategory of feed?.feed"
                fxLayout="column"
                fxFlex="0 0 40%"
            >
                <a
                    (click)="
                        processLink(subCategory?.link); itemClicked('true')
                    "
                    class="anchor"
                    [routerLink]="[subCategory?.link | pageIdFromLink]"
                    [queryParams]="subCategory?.link | queryParamFromLink"
                >
                    <h2 class="dropdown-column-content-subheading">
                        <var style="font-style: normal; margin: 0"
                            >{{ subCategory?.title?.text
                            }}<sup
                                *ngIf="subCategory?.newLaunch"
                                style="color: #c21122; font-size: 12px"
                                >New</sup
                            ></var
                        >
                    </h2>
                </a>
                <a
                    class="anchor"
                    *ngFor="let subItem of subCategory?.feed"
                    (click)="processLink(subItem?.link); itemClicked('true')"
                    [routerLink]="[subItem?.link | pageIdFromLink]"
                    [queryParams]="subItem?.link | queryParamFromLink"
                >
                    <p
                        [ngClass]="{
                            'footer-title-bold': subItem?.bold === true,
                            'footer-title-uline': subItem?.underline === true
                        }"
                        class="dropdown-column-content-item"
                        [ngStyle]="{ color: subItem.title.color }"
                    >
                        <var style="font-style: normal; margin: 0"
                            >{{ subItem?.title?.text
                            }}<sup
                                *ngIf="subItem?.newLaunch"
                                style="color: #c21122; font-size: 12px"
                                >New</sup
                            ></var
                        >
                    </p>
                </a>
            </div>
        </div>
        2-row
        <div
            *ngIf="feed?.layout === '2-row'"
            fxLayout="column"
            class="dropdown-column-content"
        >
            <div *ngFor="let subCategory of feed?.feed">
                <div
                    *ngIf="subCategory.layout === '2-col'"
                    fxLayout="row wrap"
                    fxLayoutAlign="start"
                >
                    <div
                        *ngFor="let sub of subCategory?.feed"
                        fxLayout="column"
                        fxFlex="1 1 45%"
                    >
                        <a
                            (click)="
                                processLink(sub?.link); itemClicked('true')
                            "
                            class="anchor"
                            [routerLink]="[sub?.link | pageIdFromLink]"
                            [queryParams]="sub?.link | queryParamFromLink"
                        >
                            <h1 class="dropdown-column-content-heading">
                                <var style="font-style: normal; margin: 0"
                                    >{{ sub?.title?.text
                                    }}<sup
                                        *ngIf="sub?.newLaunch"
                                        style="color: #c21122; font-size: 12px"
                                        >New</sup
                                    ></var
                                >
                            </h1>
                        </a>
                        <a
                            (click)="
                                processLink(sub?.link); itemClicked('true')
                            "
                            class="anchor"
                            *ngFor="let subItem of sub?.feed"
                            [routerLink]="[sub?.link | pageIdFromLink]"
                            [queryParams]="sub?.link | queryParamFromLink"
                        >
                            <p class="dropdown-column-content-item">
                                <var style="font-style: normal; margin: 0"
                                    >{{ subItem?.title?.text
                                    }}<sup
                                        *ngIf="subItem?.newLaunch"
                                        style="color: #c21122; font-size: 12px"
                                        >New</sup
                                    ></var
                                >
                            </p>
                        </a>
                    </div>
                </div>
            </div>
            <div *ngFor="let subCategory of feed?.feed">
                <div
                    *ngIf="subCategory?.layout === '1-column'"
                    fxLayout="column"
                >
                    <a
                        (click)="
                            processLink(subCategory?.link); itemClicked('true')
                        "
                        class="anchor"
                        [routerLink]="[subCategory?.link | pageIdFromLink]"
                        [queryParams]="subCategory?.link | queryParamFromLink"
                    >
                        <h1 class="dropdown-column-content-heading">{{
                            subCategory?.title?.text
                        }}</h1>
                    </a>
                    <div
                        *ngFor="let sub of subCategory?.feed"
                        fxLayout="column"
                    >
                        <a
                            (click)="
                                processLink(sub?.link); itemClicked('true')
                            "
                            class="anchor"
                            [routerLink]="[sub?.link | pageIdFromLink]"
                            [queryParams]="sub?.link | queryParamFromLink"
                        >
                            <p class="dropdown-column-content-item">
                                <var style="font-style: normal; margin: 0"
                                    >{{ sub?.title?.text
                                    }}<sup
                                        *ngIf="sub?.newLaunch"
                                        style="color: #c21122; font-size: 12px"
                                        >New</sup
                                    ></var
                                >
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <div
            *ngIf="feed?.layout === '1-separate-col'"
            class="dropdown-column-content"
            fxLayout="row"
            fxLayoutGap="25px"
            style="padding-top: 12px"
        >
            <div
                *ngFor="let subCategory of feed?.feed"
                fxLayout="column"
                fxFlex="0 0 17%"
            >
                <a
                    (click)="
                        processLink(subCategory?.link); itemClicked('true')
                    "
                    class="anchor"
                    [routerLink]="[subCategory?.link | pageIdFromLink]"
                    [queryParams]="subCategory?.link | queryParamFromLink"
                >
                    <h2 class="dropdown-column-content-subheading">
                        <var style="font-style: normal; margin: 0"
                            >{{ subCategory?.title?.text
                            }}<sup
                                *ngIf="subCategory?.newLaunch"
                                style="color: #c21122; font-size: 12px"
                                >New</sup
                            ></var
                        >
                    </h2>
                </a>
                <a
                    class="anchor"
                    *ngFor="let subItem of subCategory?.feed"
                    (click)="processLink(subItem?.link); itemClicked('true')"
                    [routerLink]="[subItem?.link | pageIdFromLink]"
                    [queryParams]="subItem?.link | queryParamFromLink"
                >
                    <p
                        [ngClass]="{
                            'footer-title-bold': subItem?.bold === true,
                            'footer-title-uline': subItem?.underline === true
                        }"
                        class="dropdown-column-content-item"
                        [ngStyle]="{ color: subItem.title.color }"
                    >
                        <var style="font-style: normal; margin: 0"
                            >{{ subItem?.title?.text
                            }}<sup
                                *ngIf="subItem?.newLaunch"
                                style="color: #c21122; font-size: 12px"
                                >New</sup
                            ></var
                        >
                    </p>
                </a>
            </div>
        </div>
        Image
        <div class="dropdown-column-filler" *ngIf="feed?.layout === 'image'">
            <img
                (click)="processLink(feed?.link); itemClicked('true')"
                src="{{ feed?.image }},w-1000"
                class="dropdown-column-filler__image"
                appImageCDN
            />
        </div>
        Image-grid
        <div
            *ngIf="feed?.layout === 'image-grid'"
            class="dropdown-column-filler-grid"
            fxLayout="row"
            fxFlex.gt-xs="0 0 35%"
        >
            <div class="imageGrid" *ngFor="let feed of feed?.feed">
                <picture>
                    <img
                        (click)="processLink(feed?.link); itemClicked('true')"
                        src="{{ feed?.image }}?tr=cm-pad_resize,w-453,dpr-1 "
                        class="dropdown-column-filler-grid__image-grid"
                    />
                </picture>
            </div>
        </div>
        2-Image-grid
        <div
            *ngIf="feed?.layout === '2-image-grid'"
            class="dropdown-column-filler-grid"
            fxLayout="row"
        >
            <div class="imageGrid-2" *ngFor="let imageFeed of feed?.feed">
                <picture>
                    <img
                        (click)="
                            processLink(imageFeed?.link); itemClicked('true')
                        "
                        src="{{ imageFeed?.image }}"
                        class="dropdown-column-filler-grid__image-grid"
                        appImageCDN
                    />
                </picture>
            </div>
        </div>
    </div>
</div> -->

<!-- v2-changes -->

<div
    fxLayout="row"
    fxLayoutAlign="center"
    style="
        padding: 0 32px;
        border-color: #f7f7f7;
        border-top-width: 2px;
        border-top-style: solid;
        box-shadow: rgb(158 158 158 / 8%) 0px 7px 11px 0px;
        min-height: 270px;
    "
>
    <app-dropdown-shimmer
        *ngIf="ghosts.length > 0"
        [ghosts]="ghosts"
        class="dropdown-column-shimmer"
    ></app-dropdown-shimmer>
    <div
        *ngFor="let feed of data?.feed; let i = index"
        fxLayout="column"
        fxLayoutAlign="start"
        class="dropdown-column"
        [ngStyle]="{
            'border-left':
                data?.feed!![i]?.layout === 'collection-image-with-feed'
                    ? '0.5px solid #D5D5D5'
                    : 'none',
            padding:
                data?.feed!![i]?.layout === 'collection-image-with-feed'
                    ? '32px 18px 0 18px'
                    : '32px 0',
            'padding-bottom':
                data?.feed!![i]?.layout === 'image-with-title' ? '0' : '32px',
            'border-width':
                data?.feed!![i]?.layout === 'collection-image-with-feed' ||
                data?.feed!![i + 1]?.layout === '2-col'
                    ? '0 0 0 0.5px'
                    : '0 0px 0 0'
        }"
        fxFlex="{{ feedBasedFlex[feed?.layout] || '1 1 auto' }}"
    >
        <!-- image-with-feed -->
        <div *ngIf="feed?.layout === 'image-with-feed'">
            <div fxLayout="column" fxLayoutAlign="center center">
                <a
                    (click)="processLink(feed?.link); itemClicked('true')"
                    class="anchor"
                    [routerLink]="[feed?.link | pageIdFromLink]"
                    [queryParams]="feed?.link | queryParamFromLink"
                >
                    <div style="width: 142px">
                        <picture>
                            <img
                                style="width: 100%; margin-bottom: 10px"
                                src="{{ feed?.image }}"
                                alt=""
                                appImageCDN
                            />
                        </picture>
                    </div>
                </a>
                <div>
                    <a
                        (click)="processLink(feed?.link); itemClicked('true')"
                        class="anchor"
                        [routerLink]="[feed?.link | pageIdFromLink]"
                        [queryParams]="feed?.link | queryParamFromLink"
                    >
                        <img
                            [ngStyle]="{
                                marginBottom: feed?.icon ? '6px' : '0',
                                marginTop: feed?.icon ? '-14px' : '0'
                            }"
                            *ngIf="feed?.icon"
                            class="dropDown-icon"
                            src="{{ feed?.icon }}"
                            appImageCDN
                        />
                        <h1
                            *ngIf="feed?.title"
                            [ngStyle]="{
                                paddingLeft: feed?.icon ? '11px' : '0'
                            }"
                            class="
                                dropdown-column-content-heading-v2
                                dropdown-column-content-heading-list-v2
                            "
                        >
                            <var style="font-style: normal; margin: 0">
                                {{ feed?.title?.text }}
                                <sup
                                    *ngIf="feed?.newLaunch"
                                    style="color: #c21122; font-size: 12px"
                                >
                                    New
                                </sup>
                            </var>
                        </h1>
                    </a>

                    <div *ngFor="let subfeed of feed.feed">
                        <a
                            (click)="
                                processLink(subfeed?.link); itemClicked('true')
                            "
                            class="anchor"
                            [routerLink]="[subfeed?.link | pageIdFromLink]"
                            [queryParams]="subfeed?.link | queryParamFromLink"
                        >
                            <p
                                [ngClass]="{
                                    'footer-title-bold': subfeed?.bold === true,
                                    'footer-title-uline':
                                        subfeed?.underline === true
                                }"
                                class="dropdown-column-content-item"
                                [ngStyle]="{ color: subfeed.title.color }"
                            >
                                <var>
                                    {{ subfeed?.title?.text }}
                                    <sup
                                        *ngIf="subfeed?.newLaunch"
                                        style="color: #c21122; font-size: 12px"
                                        >New</sup
                                    ></var
                                >
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- collection-image-with-feed -->
        <div *ngIf="feed?.layout === 'collection-image-with-feed'">
            <div fxLayout="column" fxLayoutAlign="center center">
                <a
                    (click)="processLink(feed?.link); itemClicked('true')"
                    class="anchor"
                    [routerLink]="[feed?.link | pageIdFromLink]"
                    [queryParams]="feed?.link | queryParamFromLink"
                >
                    <div style="width: 142px">
                        <picture>
                            <img
                                style="width: 100%; margin-bottom: 10px"
                                src="{{ feed?.image }}"
                                alt=""
                                appImageCDN
                            />
                        </picture>
                    </div>
                </a>
                <div>
                    <a
                        (click)="processLink(feed?.link); itemClicked('true')"
                        class="anchor"
                        [routerLink]="[feed?.link | pageIdFromLink]"
                        [queryParams]="feed?.link | queryParamFromLink"
                    >
                        <img
                            [ngStyle]="{ marginBottom: feed?.icon ? '0' : '0' }"
                            *ngIf="feed?.icon"
                            class="dropDown-icon"
                            src="{{ feed?.icon }}"
                            appImageCDN
                        />
                        <h1
                            *ngIf="feed?.title"
                            [ngStyle]="{
                                paddingLeft: feed?.icon ? '11px' : '0'
                            }"
                            class="
                                dropdown-column-content-heading-v2
                                dropdown-column-content-heading-list-v2
                            "
                        >
                            <var style="font-style: normal; margin: 0">
                                {{ feed?.title?.text }}
                                <sup
                                    *ngIf="feed?.newLaunch"
                                    style="color: #c21122; font-size: 12px"
                                >
                                    New
                                </sup>
                            </var>
                        </h1>
                    </a>
                    <div *ngFor="let subfeed of feed.feed">
                        <a
                            (click)="
                                processLink(subfeed?.link); itemClicked('true')
                            "
                            class="anchor"
                            [routerLink]="[subfeed?.link | pageIdFromLink]"
                            [queryParams]="subfeed?.link | queryParamFromLink"
                        >
                            <p
                                [ngClass]="{
                                    'footer-title-bold': subfeed?.bold === true,
                                    'footer-title-uline':
                                        subfeed?.underline === true
                                }"
                                class="dropdown-column-content-item"
                                [ngStyle]="{ color: subfeed.title.color }"
                            >
                                <var>
                                    {{ subfeed?.title?.text }}
                                    <sup
                                        *ngIf="subfeed?.newLaunch"
                                        style="color: #c21122; font-size: 12px"
                                        >New</sup
                                    ></var
                                >
                            </p>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <!-- image-with-title -->
        <div
            *ngIf="feed.layout === 'image-with-title'"
            fxLayout="row wrap"
            fxLayoutAlign="space-evenly"
        >
            <div
                *ngFor="let subfeed of feed.feed"
                fxFlex="0 0 30%"
                style="padding-bottom: 32px"
            >
                <div style="width: 142px">
                    <a
                        (click)="
                            processLink(subfeed?.link); itemClicked('true')
                        "
                        class="anchor"
                        [routerLink]="[subfeed?.link | pageIdFromLink]"
                        [queryParams]="subfeed?.link | queryParamFromLink"
                    >
                        <picture>
                            <img
                                style="width: 100%; margin-bottom: 10px"
                                src="{{ subfeed?.image }}"
                                alt=""
                                appImageCDN
                            />
                        </picture>
                        <div>
                            <img
                                [ngStyle]="{
                                    marginBottom: subfeed?.icon ? '0' : '0'
                                }"
                                *ngIf="subfeed?.icon"
                                class="dropDown-icon"
                                src="{{ subfeed?.icon }}"
                                appImageCDN
                            />
                            <h1
                                *ngIf="subfeed?.title"
                                [ngStyle]="{
                                    paddingLeft: feed?.icon ? '11px' : '0'
                                }"
                                class="
                                    dropdown-column-content-heading-v2
                                    dropdown-column-content-heading-list-v2
                                "
                            >
                                <var style="font-style: normal; margin: 0">
                                    {{ subfeed?.title?.text }}
                                    <sup
                                        *ngIf="subfeed?.newLaunch"
                                        style="color: #c21122; font-size: 12px"
                                    >
                                        New
                                    </sup>
                                </var>
                            </h1>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
