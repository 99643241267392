import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
} from '@angular/core';
import { MarcheAPIService } from '../@core/api/marche-api.service';
import { CPData } from './category-page.data';
import { Subscriber } from 'rxjs';
import { plainToClassFromExist } from 'class-transformer';
import {
    ErrorWrapper,
    ResponseWrapper,
} from '../@shared/models/response-wrapper.model';
import { SeoService } from '../@core/seo.service';
import { FooterPageService } from '../footer/footer-page.service';
import { ApplicationRouterService } from '../@core/application-router/application-router.service';
import { OfferToggleService } from '../offer-toggle/offer-toggle.service';

@Component({
    selector: 'app-category-page',
    templateUrl: './category-page.component.html',
    styleUrls: ['./category-page.component.scss'],
})
export class CategoryPageComponent implements OnInit, OnChanges {
    static readonly CP_DATA_API = '/cp/v2';
    static readonly CP_SEO_API = '/cp/seo';

    @Input() display = '';
    @Input() filter: { [p: string]: string | string[] } = {};
    @Input() fullPage = true;
    @Input() activeTab = 0;
    @Output() processLink: EventEmitter<string> = new EventEmitter();

    loading = true;
    itemClicked = '';
    footerContent = '';

    data: CPData = new CPData();
    showShimmer = true;
    shimmerItems = [1, 2, 3, 4];

    errors: ErrorWrapper[] = [];
    filters: { [p: string]: string | string[] } = {};
    isTimerStrip = false;

    constructor(
        private marcheAPIService: MarcheAPIService,
        private seoService: SeoService,
        private footerService: FooterPageService,
        private applicationRouterService: ApplicationRouterService,
        private OfferToggleService: OfferToggleService
    ) {
        this.OfferToggleService.OfferToggle({ ...this.filters, page: 'cp' });
    }

    private layoutBasedFlex: Record<string, string> = {
        center: '0 0 40%',
        'center-center': '0 0 100%',
        'center-start': '0 0 40%',
        default: '0 0 40%',
    };
    private layoutBasedOrder: Record<string, string> = {
        'center-end': '1',
        'end-center': '1',
        'center-start': '0',
        'end-start': '1',
        default: '0',
    };

    ngOnInit(): void {
        //  this.updateCategoryPageData();
        //   this.footerService.updateFilter({ ...this.filter, page: 'cp' });
        this.processLink.emit('/fn/internal?page=cp&action=nav-update');
    }

    externalLinksRedirect(link: string) {
        console.log(link);
        window.open(link, '_blank');
    }

    updateCategoryPageData() {
        this.showShimmer = true;
        const subscriber = Subscriber.create<ResponseWrapper<CPData> | null>(
            value => {
                //   this.applicationRouterService.differNavApiCall.emit({ navCall: true, pageId: 'cp' });
                if (value?.success) {
                    plainToClassFromExist(this.data, value?.data);
                    if (this.data.timerStrip?.layout == 'timer-strip') {
                        this.startTimer(this.data.timerStrip.endsIn);
                        this.isTimerStrip = this.isfutureDateGreter(
                            this.data.timerStrip.endsIn
                        );
                    }
                    this.loading = false;
                } else {
                    plainToClassFromExist(this.errors, value?.errors);
                }
            },
            error => {},
            () => {
                this.showShimmer = false;
            }
        );

        this.marcheAPIService
            .getRequest<ResponseWrapper<CPData> | null>(
                CategoryPageComponent.CP_DATA_API,
                this.filter
            )
            .subscribe(subscriber);
    }

    // executeFunctionLink(id: string, query: { [p: string]: string | string[] }) {
    //   switch (query?.action) {
    //     case 'change-tab':
    //       console.log(query);
    //       break;
    //     default: {
    //     //  super.executeFunctionLink(id, query);
    //     }
    //   }
    // }

    itemSelected(value: string) {
        this.itemClicked = value;
    }

    ngOnChanges() {
        if (this.fullPage) {
            this.updateSeo();
        }
        this.updateCategoryPageData();
    }

    updateSeo() {
        this.marcheAPIService
            .getRequest<ResponseWrapper<any>>(
                CategoryPageComponent.CP_SEO_API,
                this.filter
            )
            .subscribe(res => {
                if (res?.success) {
                    this.footerContent = res?.data?.footerContent;
                    this.seoService.updateMetaTitle(res?.data?.meta?.title);
                    this.seoService.updateMetaDescription(
                        res?.data?.meta?.description
                    );
                    this.seoService.updateCanonical(res?.data?.canonical);
                    this.seoService.cacheSingleUrl(
                        window.location.host + res?.data?.canonical
                    );
                } else {
                    this.seoService.updateMetaTitle('DailyObjects');
                    this.seoService.updateMetaDescription('Description');
                    this.seoService.updateCanonical('');
                }
            });
    }

    getFlex(item: any): string {
        return (
            this.layoutBasedFlex[item.alignment] || this.layoutBasedFlex.default
        );
    }

    getFlexOrder(item: any) {
        return (
            this.layoutBasedOrder[item?.alignment] ||
            this.layoutBasedOrder.default
        );
    }
    day: number = 0;
    hours: number = 0;
    mins: number = 0;
    seconds: number = 0;
    interval: any;

    startTimer(endInDate: string): string {
        this.interval = setInterval(() => {
            var futureDate = new Date(endInDate).getTime();
            var Today = new Date().getTime();
            var distance = futureDate - Today;
            var second = distance / 1000;
            var d = Math.floor(second / (3600 * 24));
            var h = Math.floor((second % (3600 * 24)) / 3600);
            var m = Math.floor((second % 3600) / 60);
            var s = Math.floor(second % 60);

            // Check if hours exceed 24 and adjust day accordingly
            if (h >= 24) {
                d += Math.floor(h / 24);
                h = h % 24;
            }
            this.day = d;
            this.hours = h;
            this.mins = m;
            this.seconds = s;
            if (Today < 0) {
                clearInterval(this.interval);
            }
            if (!this.isfutureDateGreter(endInDate)) {
                this.isTimerStrip = false;
            }
        }, 1000);
        return '';
    }

    isfutureDateGreter(endInDate: string) {
        var futureDate = new Date(endInDate).getTime();
        var Today = new Date().getTime();
        return futureDate > Today;
    }
}
