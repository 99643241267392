import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MarcheAPIService } from './api/marche-api.service';
import { ApplicationRouterModule } from './application-router/application-router.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AnalyticsService } from './analytics.service';

@NgModule({
    providers: [
        AnalyticsService,
        MarcheAPIService,
        { provide: ErrorHandler, useValue: undefined },
    ],
    declarations: [],
    imports: [
        CommonModule,
        HttpClientModule,
        MatDialogModule,
        MatSnackBarModule,
    ],
    exports: [ApplicationRouterModule],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                `${parentModule} has already been loaded. Import Core module in the AppModule only.`
            );
        }
    }
}
