import { EventEmitter, Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { ResponseWrapper } from '../@shared/models/response-wrapper.model';
import { MarcheAPIService } from '../@core/api/marche-api.service';
import { Clickable } from '../@shared/models/clickable.model';

@Injectable({
    providedIn: 'root',
})
export class OfferToggleService {
    static readonly OT = '/offerToggle';
    pageData: any;
    footerFilter: EventEmitter<{
        [p: string]: string | string[];
    }> = new EventEmitter<{ [p: string]: string | string[] }>();

    breadcrumb: EventEmitter<any[]> = new EventEmitter<any[]>();

    constructor(private marcheAPIService: MarcheAPIService) {}

    OfferToggle(filter: { [p: string]: string | string[] }): Observable<any> {
        return this.marcheAPIService.getRequest<ResponseWrapper<any>>(
            OfferToggleService.OT,
            filter
        );
    }
}
