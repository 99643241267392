import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponseWrapper } from '../@shared/models/response-wrapper.model';
import { MarcheAPIService } from './api/marche-api.service';
import { Subscriber } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WhatmoreService {
    constructor(private marcheAPIService: MarcheAPIService) {}

    WhatmoreEvents(orderId: string, items: []) {
        const data = {
            orderId: orderId,
            orderItems: items,
            viewedProducts: localStorage._whatmore_viewed_products
                ? localStorage._whatmore_viewed_products
                : [],
            addToCartProducts: localStorage._whatmore_add_to_cart_products
                ? localStorage._whatmore_add_to_cart_products
                : [],
        };

        const subscriber = Subscriber.create<ResponseWrapper<any> | null>(
            value => {},
            error => {},
            () => {}
        );
        return this.marcheAPIService
            .postRequest<ResponseWrapper<any> | null>(
                '/whatmore/order-tracking',
                undefined,
                data
            )
            .subscribe(subscriber);
    }
}
