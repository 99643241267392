import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RouterPreloadStratergy extends PreloadingStrategy {
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        super();
    }

    preload(route: Route, load: Function): Observable<any> {
        let loadPaths: Array<string> = [];
        let loadPath = false;
        let currentPath = '';
        if (isPlatformBrowser(this.platformId)) {
            currentPath = window.location.pathname;
        }
        if (currentPath === '/' || currentPath.includes('/hp')) {
            loadPaths = ['lp'];
        } else if (currentPath.includes('/lp')) {
            loadPaths = ['dp'];
        } else if (currentPath.includes('/rfp')) {
            loadPaths = ['lp'];
        }
        const toBePreloaded = (path: any) => {
            return loadPaths.includes(path);
        };

        loadPath = toBePreloaded(route.path);
        return loadPath ? timer(15000).pipe(flatMap(_ => load())) : of(null);
    }
}
