import { ErrorHandler, Inject, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CoreModule } from './@core/core.module';
import { CategoryPageModule } from './category-page/category-page.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { Router } from '@angular/router';
// @ts-ignore
import { ApmErrorHandler, ApmService } from '@elastic/apm-rum-angular';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgOtpInputModule } from 'ng-otp-input';
import { SharedModule } from './@shared/shared.module';
import { CommonModule } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';
import { OfferVideoPopupComponent } from './offer-video-popup/offer-video-popup.component';
import { SubscriptionPopupPageComponent } from './subscription-popup-page/subscription-popup-page.component';
import { SubscriptionRemovePageComponent } from './subscription-remove-page/subscription-remove-page.component';
import { MembershipAddPopupComponent } from './membership-add-popup/membership-add-popup.component';
import { MembershipSuccessComponent } from './membership-success/membership-success.component';
import { InternationalPopupComponent } from './international-popup/international-popup.component';
import { InternationalPopupModule } from './international-popup/international-popup.module';
import { PaymentProcessingModule } from './payment-processing/payment-processing.module';
import { MatRadioModule } from '@angular/material/radio';

@NgModule({
    declarations: [
        AppComponent,
        OfferVideoPopupComponent,
        SubscriptionPopupPageComponent,
        SubscriptionRemovePageComponent,
        MembershipAddPopupComponent,
        MembershipSuccessComponent,
    ],
    imports: [
        CoreModule,
        CommonModule,
        SharedModule,
        BrowserAnimationsModule,
        FlexLayoutModule.withConfig({
            addOrientationBps: false,
            serverLoaded: false,
        }),
        MatSidenavModule,
        PaymentProcessingModule,
        MatToolbarModule,
        MatListModule,
        MatButtonModule,
        CategoryPageModule,
        NgOtpInputModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: false,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            //   registrationStrategy: 'registerWhenStable:30000'
        }),
        ServiceWorkerModule.register('gcm_service_worker.js', {
            enabled: true,
        }),
        InternationalPopupModule,
        MatRadioModule,
    ],
    providers: [
        {
            provide: ErrorHandler,
            useClass: ApmErrorHandler,
        },
        {
            provide: ApmService,
            useClass: ApmService,
            deps: [Router],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(@Inject(ApmService) service: ApmService) {
        const apmEndpoint = environment.logging.apm.endpoint;
        if (apmEndpoint) {
            service.init({
                serviceName: environment.name,
                serverUrl: environment.logging.apm.endpoint,
            });
        }
    }
}
