import { EventEmitter, Injectable } from '@angular/core';
import { FPData } from './footer-page.data';
import { Observable, of } from 'rxjs';
import { ResponseWrapper } from '../@shared/models/response-wrapper.model';
import { MarcheAPIService } from '../@core/api/marche-api.service';
import { Clickable } from '../@shared/models/clickable.model';

@Injectable({
    providedIn: 'root',
})
export class FooterPageService {
    static readonly FP_DATA_API = '/footer';
    footerData: FPData | undefined | null = new FPData();
    footerFilter: EventEmitter<{
        [p: string]: string | string[];
    }> = new EventEmitter<{ [p: string]: string | string[] }>();

    breadcrumb: EventEmitter<any[]> = new EventEmitter<any[]>();

    constructor(private marcheAPIService: MarcheAPIService) {}

    updateFilter(filters: { [p: string]: string | string[] }) {
        // this.footerFilter = of(filters);
        //    this.footerFilter.emit(filters);
    }

    updateFooter(filter: { [p: string]: string | string[] }): Observable<any> {
        return this.marcheAPIService.getRequest<ResponseWrapper<FPData>>(
            FooterPageService.FP_DATA_API,
            filter
        );
        // .pipe(
        // tap(res1 => {
        //   if (res1?.success === false) {
        //     this.footerData = null;
        //   } else {
        //     this.footerData = res1?.data;
        //   }
        //   console.log(this.footerData);
        // }),
        //  );
    }

    flushBreadcrumb() {
        this.breadcrumb.emit([]);
    }
}
