import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'lineBreak',
})
export class LineBreakPipe implements PipeTransform {
    constructor() {}
    transform(text: string): string {
        return text.replace(new RegExp('\n', 'g'), '<br/>');
    }
}
