import { MatSnackBar } from '@angular/material/snack-bar';
import {
    ApplicationRouterService,
    ERouteType,
} from './application-router.service';

export interface RouteExecutionInterface {
    executePageLink(
        pageId: string,
        query: { [p: string]: string | string[] },
        params: any
    ): void;

    executeFunctionLink(
        id: string,
        query: { [p: string]: string | string[] },
        body?: object
    ): void;

    processLink(link: string): void;
}

export abstract class RouteExecutor implements RouteExecutionInterface {
    protected constructor(
        protected applicationRouterService: ApplicationRouterService,
        protected snackBar?: MatSnackBar
    ) {}

    executePageLink(
        pageId: string,
        query: { [p: string]: string | string[] },
        params?: any
    ) {
        this.applicationRouterService.executePageLink(pageId, query, params);
    }

    executeFunctionLink(
        id: string,
        query: { [p: string]: string | string[] },
        body?: object
    ) {
        this.applicationRouterService.executeFunctionLink(id, query, body);
    }

    processLink(link: string) {
        if (!!link) {
            const routeParse = this.applicationRouterService.parseLink(link);
            switch (routeParse.type) {
                case ERouteType.FUNCTION:
                    this.executeFunctionLink(routeParse.id, routeParse.query);
                    break;
                case ERouteType.PAGE:
                    this.executePageLink(
                        routeParse.id,
                        routeParse.query,
                        routeParse.params
                    );
                    break;
            }
        }
    }
}
