import {
    Component,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Optional,
    SimpleChanges,
} from '@angular/core';
import { MarcheAPIService } from '../../@core/api/marche-api.service';
import { ApplicationRouterService } from '../../@core/application-router/application-router.service';
import { RouteExecutor } from '../../@core/application-router/route.executor';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ErrorWrapper } from '../models/response-wrapper.model';
import { ErrorPageData } from './error-page.data';

@Component({
    selector: 'app-error-page',
    templateUrl: './error-page.component.html',
    styleUrls: ['./error-page.component.scss'],
})
export class ErrorPageComponent
    extends RouteExecutor
    implements OnInit, OnChanges
{
    // @Input() asHTMLTag: boolean = false;
    loading: boolean = false;
    isPagePopup = false;
    @Input() error: ErrorWrapper[] | ErrorPageData[] | any;
    @Input() asFullPage = true;

    constructor(
        private marcheAPIService: MarcheAPIService,
        applicationRouterService: ApplicationRouterService,
        @Optional() public dialogRef: MatDialogRef<ErrorPageComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) public dataDialog: any
    ) {
        super(applicationRouterService);
    }

    ngOnInit() {}

    backButton() {
        window.history.back();
    }

    ngOnChanges(changes: SimpleChanges): void {}

    executeFunctionLink(id: string, query: { [p: string]: string | string[] }) {
        if (query.action === 'back-pressed') {
            this.backButton();
        } else {
            super.executeFunctionLink(id, query);
        }
    }
}
