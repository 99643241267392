import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
    ResponseWrapper,
    WizResponseWrapper,
} from '../@shared/models/response-wrapper.model';
import { ViewEvent } from '../search-page/search-page.data';
import { MarcheAPIService } from './api/marche-api.service';
import { Subscriber } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class WizzyService {
    
    constructor(private marcheAPIService: MarcheAPIService) {}

    wizzyViewEvents(data: Record<string, unknown>) {
        const subscriber =
            Subscriber.create<WizResponseWrapper<ViewEvent> | null>(
                value => {},
                error => {},
                () => {}
            );
        return this.marcheAPIService
            .postRequest<WizResponseWrapper<ViewEvent> | null>(
                '/event/wizzy',
                undefined,
                data,
            )
            .subscribe(subscriber);
    }

    // wizzyClickEvents(): Observable<any> {
    //     return this.marcheAPIService.postRequest<WizResponseWrapper<ViewEvent> | null>(
    //         WizzyService.SAVE_CLICK
    //     );
    // }

    wizzyConvertedEvents(data: Record<string, unknown>) {
        const subscriber =
            Subscriber.create<WizResponseWrapper<ViewEvent> | null>(
                value => {},
                error => {},
                () => {}
            );
        return this.marcheAPIService
            .postRequest<WizResponseWrapper<ViewEvent> | null>(
                '/event/wizzy',
                undefined,
                data
            )
            .subscribe(subscriber);
    }
}
