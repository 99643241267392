import { Injectable } from '@angular/core';
import { MarcheAPIService } from '../../@core/api/marche-api.service';
import { Observable, of } from 'rxjs';
import { RouteExecutor } from '../../@core/application-router/route.executor';
import { ApplicationRouterService } from '../../@core/application-router/application-router.service';
import { DropdownPageData } from './dropdown-page.data';
import { ResponseWrapper } from '../models/response-wrapper.model';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DropdownPageService extends RouteExecutor {
    private readonly DDP_API = '/fn/ddp';
    cacheData: Map<string, object> = new Map<string, object>();

    constructor(
        private marcheAPIService: MarcheAPIService,
        applicationRouterService: ApplicationRouterService
    ) {
        super(applicationRouterService);
    }

    getData(parameters: {
        [p: string]: string | string[];
    }): Observable<ResponseWrapper<DropdownPageData> | any> {
        if (this.cacheData.has(parameters?.f?.toString()))
            return of(this.cacheData.get(parameters?.f?.toString()));
        else
            return this.marcheAPIService
                .getRequest<ResponseWrapper<DropdownPageData> | null>(
                    this.DDP_API,
                    parameters
                )
                .pipe(
                    tap((res: any) => {
                        this.cacheData.set(parameters?.f?.toString(), res);
                    })
                );
    }
}
