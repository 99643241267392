<app-error-page *ngIf="errorCaught" [error]="errors"></app-error-page>

<div *ngIf="data && !errorCaught">
    <div *ngIf="showLoader" style="margin: 30px; margin-top: 100px">
        <p class="payment-title">{{ data?.title?.text }}</p>
    </div>
    <div class="countdown-timer">
        <svg viewBox="0 0 36 36" class="circular-chart">
            <path
                class="circle-bg"
                d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
            />
            <path
                class="circle-progress"
                [attr.stroke-dasharray]="circumference"
                [attr.stroke-dashoffset]="progressBar"
                d="M18 2.0845
              a 15.9155 15.9155 0 0 1 0 31.831
              a 15.9155 15.9155 0 0 1 0 -31.831"
            />
        </svg>
        <p class="timer"> {{ timeRemaining$ | async }}</p>
        <p class="timerUnit" *ngIf="(timeRemaining$ | async)?.length > 0">
            mins
        </p>
    </div>
    <div class="approveBox">
        <p class="approveBoxText">{{ data?.description?.text }}</p>
        <p class="approveBoxSubText">{{ data?.warningText?.text }}</p>
    </div>
</div>
