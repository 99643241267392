import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PaymentProcessingComponent } from './payment-processing.component';
import { PopupShimmerModule } from '../popup-shimmer/popup-shimmer-module';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { SharedModule } from '../@shared/shared.module';

@NgModule({
    declarations: [PaymentProcessingComponent],
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
        FlexLayoutModule,
        PopupShimmerModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        SharedModule,
    ],
})
export class PaymentProcessingModule {}
