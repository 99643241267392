import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-dropdown-shimmer',
    templateUrl: './dropdown-shimmer.component.html',
    styleUrls: ['./dropdown-shimmer.component.scss'],
})
export class DropdownShimmerComponent {
    @Input() ghosts?: any[];
}
