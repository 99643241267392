import { Component, Inject, OnInit } from '@angular/core';
import { ErrorWrapper } from '../@shared/models/response-wrapper.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RouteExecutor } from '../@core/application-router/route.executor';
import { ApplicationRouterService } from '../@core/application-router/application-router.service';

@Component({
    selector: 'app-international-popup',
    templateUrl: './international-popup.component.html',
    styleUrls: ['./international-popup.component.scss'],
})
export class InternationalPopupComponent
    extends RouteExecutor
    implements OnInit
{
    errors: ErrorWrapper[] = [];
    popupData: any;

    readonly COUNTRY_ACCEPTANCE_FLAG = 'COUNTRY_ACCEPTANCE_FLAG';
    countryPreferenceHeaderVisibility = false;

    loading = true;

    constructor(
        applicationRouterService: ApplicationRouterService,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<InternationalPopupComponent>
    ) {
        super(applicationRouterService);
    }

    ngOnInit(): void {
        this.updateData(this.data);
    }

    updateData(data: any) {
        this.popupData = data;
    }

    closeDialog() {
        this.dialogRef.close();
    }

    executeFunctionLink(id: string, query: { [p: string]: string | string[] }) {
        if (query.action === 'close-popup') {
            this.closeDialog();
        } else {
            super.executeFunctionLink(id, query);
        }
    }

    secondaryPreferenceButton() {
        this.setAcceptedCountryPreference();
        if (!!this.popupData.secondaryButton.button.link) {
            window.location.assign(this.popupData.secondaryButton.button.link);
        }
        this.hideCountryPreference();
    }

    primaryPreferenceButton() {
        this.setAcceptedCountryPreference();
        //   this.hideCountryPreference();
        if (!!this.popupData.primaryButton.button.link) {
            window.location.assign(this.popupData.primaryButton.button.link);
        }
    }

    private setAcceptedCountryPreference() {
        localStorage.setItem(this.COUNTRY_ACCEPTANCE_FLAG, 'true');
    }

    private getAcceptedCountryPreferenceFlag(): boolean {
        return JSON.parse(
            localStorage.getItem(this.COUNTRY_ACCEPTANCE_FLAG) || 'false'
        );
    }

    private hideCountryPreference() {
        this.popupData = null;
        this.countryPreferenceHeaderVisibility = false;
    }
}
