import { CText } from '../models/text.model';
import { Clickable } from '../models/clickable.model';

export interface FeedLevelFour {
    link: string;
    title: CText;
    icon: string;
    feed: Clickable[];
    newLaunch?: string;
}

export interface FeedLevelThree {
    link: string;
    title: CText;
    icon: string;
    feed: FeedLevelFour[];
    newLaunch?: string;
}

export interface FeedLevelTwo {
    layout: string;
    title: CText;
    link: string;
    icon: string;
    feed: FeedLevelThree[];
    image: string;
    newLaunch?: string;
}

export interface FeedLevelOne {
    layout: string;
    title: CText;
    link: string;
    icon: string;
    feed: FeedLevelTwo[];
    image: string;
    newLaunch?: string;
}

export class DropdownPageData {
    layout: string;
    feed: FeedLevelOne[];
    bold: string;
    underline: string;

    constructor() {
        this.layout = '';
        this.feed = [];
        this.bold = '';
        this.underline = '';
    }
}
