import { MarcheAPIService } from '../@core/api/marche-api.service';

export class EventQueue {
    FLUSH_INTERVAL = 15; // seconds;
    MAX_QUEUE_LENGTH = 8;
    private items: Array<any> = [];
    private timeoutId: number | undefined = undefined;

    constructor(private marcheApiService: MarcheAPIService) {}

    internalEventCall(data: any[]) {
        this.marcheApiService
            .postRequest('/event', {}, { logs: data })
            .subscribe(res => {});
    }

    add(body: any): void {
        this.items.push(body);
        if (this.items.length >= this.MAX_QUEUE_LENGTH) {
            this.flush();
        } else {
            if (!this.timeoutId) {
                this._setTimer();
            }
        }
    }

    private _setTimer() {
        this.timeoutId = window.setTimeout(
            () => this.flush(),
            this.FLUSH_INTERVAL * 1000
        );
    }

    private _clear() {
        this.items = [];
        this.timeoutId = undefined;
    }

    flush(): void {
        this.onFlush(this.items);
        this._clear();
    }

    onFlush(items: any[]) {
        if (items.length > 0) {
            this.internalEventCall(items);
        }
    }
}
