<mat-sidenav-container>
    <!--    <mat-sidenav-->
    <!--        [position]="'end'"-->
    <!--        #sidenav-->
    <!--        role="navigation"-->
    <!--        style="-->
    <!--            width: 85%;-->
    <!--            position: fixed;-->
    <!--            min-height: 100vh;-->
    <!--            overflow-y: scroll;-->
    <!--        "-->
    <!--    >-->
    <!--        <app-category-page-->
    <!--            display="vertical"-->
    <!--            (click)="sidenav.close()"-->
    <!--            [fullPage]="false"-->
    <!--        ></app-category-page>-->
    <!--    </mat-sidenav>-->
    <mat-sidenav-content fxLayout="column">
        <!--<div
            *ngIf="preferenceData && countryPreferenceHeaderVisibility"
            class="redirect-strip"
            fxLayout="row"
            fxLayoutGap="10px"
            fxLayoutAlign="flex-start center"
        >
            <p fxFlex="0 1 auto">{{ preferenceData?.message }}</p>
            <button
                (click)="leftPreferenceButton()"
                mat-flat-button
                color="primary"
                fxFlex="0 0 80px"
            >
                {{ preferenceData['leftButton']?.button?.text }}
            </button>
            <button
                (click)="rightPreferenceButton()"
                mat-flat-button
                color="primary"
                fxFlex="0 0 80px"
            >
                {{ preferenceData['rightButton']?.button?.text }}
            </button>
        </div>-->
        <!-- HEADER -->
        <mat-toolbar
            class="navbar"
            ngClass.gt-xs="navbar-desktop"
            fxLayout="column"
            fxShow="true"
            [ngStyle.xs]="{
                display: navigationShow === true ? 'none' : 'flex'
            }"
        >
            <div
                class="navigation-bar-content"
                ngClass.gt-xs="navigation-bar-content-desktop"
                fxFlex="0 0 100%"
                fxLayout="row"
                fxLayoutAlign="space-between center"
            >
                <!-- mobile only-->
                <div
                    fxFlex.xs="0 0 75px"
                    fxFlexAlign="end center"
                    fxShow.xs="true"
                    fxShow.gt-xs="false"
                    fxLayoutAlign="end center"
                >
                    <div fxFlex="1 1 auto"></div>
                    <div
                        fxLayout="row"
                        fxLayoutGap="3px"
                        fxLayoutGap.gt-xs="20px"
                    >
                        <ng-container *ngFor="let icon of data?.feed">
                            <button
                                *ngIf="icon?.align === 'left'"
                                (click)="processLink(icon?.link)"
                                mat-icon-button
                                fxFlex="0 0 40px"
                            >
                                <img
                                    appImageCDN
                                    src="{{ icon?.icon }}"
                                    class="dropdown-icon"
                                />
                                <span *ngIf="icon?.count !== 0" class="badge">{{
                                    icon?.count
                                }}</span>
                            </button>
                        </ng-container>
                    </div>
                </div>
                <a
                    [routerLink]="[data?.logo?.link | pageIdFromLink]"
                    [queryParams]="data?.logo?.link | queryParamFromLink"
                >
                    <div
                        fxFlex="0 0 150px"
                        fxFlexAlign="start center"
                        fxLayoutAlign="start center"
                    >
                        <img
                            (click)="processLink(data?.logo?.link)"
                            src="{{ data?.logo?.icon }}"
                            appImageCDN
                            alt="Store for Mobile Covers and Accessories"
                            title="Store for Mobile Covers and Accessories"
                            class="logo-image"
                            ngClass.xs="logo-image__mobile"
                        />
                    </div>
                </a>
                <app-category-page
                    *ngIf="categoryShow"
                    (processLink)="processLink($event)"
                    fxFlex="1 1 auto"
                    fxLayoutAlign="center"
                    fxFlexAlign="center center"
                    fxShow="false"
                    fxShow.gt-sm="true"
                    display="horizontal"
                    [fullPage]="false"
                ></app-category-page>
                <div
                    fxFlex.xs="0 0 75px"
                    fxFlex.gt-xs="0 0 150px"
                    fxFlexAlign="end center"
                    fxLayoutAlign="end center"
                >
                    <div fxFlex="1 1 auto"></div>
                    <div
                        fxLayout="row"
                        fxLayoutGap="3px"
                        fxLayoutGap.gt-xs="10px"
                    >
                        <ng-container *ngFor="let icon of data?.feed">
                            <button
                                *ngIf="icon?.align === 'right'"
                                (click)="processLink(icon?.link)"
                                mat-icon-button
                                fxFlex="0 0 40px"
                            >
                                <img
                                    appImageCDN
                                    src="{{ icon?.icon }}"
                                    class="dropdown-icon"
                                />
                                <span
                                    *ngIf="
                                        icon?.id === 'bp' && countViewVisibility
                                    "
                                >
                                    <span
                                        *ngIf="icon?.count < 100"
                                        class="badge"
                                    >
                                        {{ counts }}
                                    </span>
                                    <span
                                        *ngIf="
                                            icon?.count > 100 ||
                                            icon?.count == 100
                                        "
                                        class="largeBadge"
                                    >
                                        {{ counts }}
                                    </span>
                                </span>
                            </button>
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- Drop Down Content Desktop -->
            <div
                class="dropdown-content"
                fxShow="false"
                fxShow.gt-sm="{{ this.dropdownContentVisible }}"
            >
                <app-dropdown
                    [parameters]="dropdownContentParameters"
                    (closeDropDown)="closeDropDown()"
                    (mouseleave)="closeDropDown()"
                ></app-dropdown>
            </div>
        </mat-toolbar>

        <!-- BODY -->
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
