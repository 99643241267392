import { Clickable } from '../@shared/models/clickable.model';
import { Iconable } from '../@shared/models/iconable.model';

export class FPData {
    constructor() {
        this.layout = '';
        this.text = '';
        this.rating = {
            text: '',
            image: '',
        };
        this.featured = {
            text: '',
            images: [],
        };
        this.content = {
            staticPages: [{ text: '', feed: [] }],
            conformance: [],
            paymentOptions: { text: '', icons: [] },
            social: {
                text: '',
                feed: [],
            },
            download: {
                text: '',
                icon: [],
            },
            mostSearched: {
                text: '',
                feed: [],
            },
            giftdata: {
                text: '',
                feed: [],
            },
            blogs: {
                text: '',
                feed: [],
            },
        };
        this.bottomBar = {
            title: { text: '', color: '' },
            color: '',
        };
    }
    layout: string;
    text: string;
    featured: Featured;
    rating: Rating;
    content: Content;
    bottomBar: BottomBar;
}

export interface BottomBar {
    title: { text: string; color: string };
    color: string;
}

export interface Featured {
    text: string;
    images: Array<string>;
}
export interface Rating {
    text: string;
    image: string;
}
export interface Content {
    staticPages: Array<{ text: string; feed: Array<Clickable> }>;
    conformance: TextIcon[];
    paymentOptions: FPaymentOptions;
    social: FPSocial;
    download?: FPDownload;
    mostSearched: SearchedData;
    getInTouch?: {
        button: { text: string; link: string };
        placeholder: string;
        text: string;
    };
    giftdata?: SearchedData;
    blogs?: SearchedData;
}

export interface FPSocial {
    text: string;
    feed: Iconable[];
}

export interface TextIcon {
    text: string;
    icon: string;
}

export interface FPaymentOptions {
    text: string;
    icons: string[];
}

export interface FPDownload {
    text: string;
    icon: Iconable[];
}

export interface SearchedData {
    text: string;
    feed: FeedItem[];
}

export interface FeedItem {
    text: string;
    feed: Clickable[];
}
