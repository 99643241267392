<div
    class="shimmer-holder"
    fxLayoutAlign="center center"
    fxLayout="row"
    fxLayoutGap="20px"
>
    <div
        fxLayout="column"
        fxFlex="0 0 14%"
        *ngFor="let it of ghosts"
        style="padding: 25px 15px"
    >
        <span class="image-2"></span>
        <span class="custom_Heading2"></span>
        <span class="custom_Heading2"></span>
        <span class="custom_Heading2"></span>
        <span class="custom_Heading2"></span>
    </div>
    <!-- <div class="image"></div> -->
</div>
