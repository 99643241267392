import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PopupShimmerComponent } from './popup-shimmer.component';

@NgModule({
    declarations: [PopupShimmerComponent],
    imports: [CommonModule, FlexLayoutModule],
    exports: [PopupShimmerComponent],
})
export class PopupShimmerModule {}
