import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CategoryPageComponent } from './category-page.component';
import { CategoryPageRoutingModule } from './category-page-routing.module';
import { MatTabsModule } from '@angular/material/tabs';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SharedModule } from '../@shared/shared.module';
import { MatListModule } from '@angular/material/list';

@NgModule({
    declarations: [CategoryPageComponent],
    imports: [
        CommonModule,
        CategoryPageRoutingModule,
        MatTabsModule,
        FlexLayoutModule,
        MatProgressSpinnerModule,
        SharedModule,
        MatListModule,
    ],
    exports: [CategoryPageComponent],
})
export class CategoryPageModule {
    constructor() {}
}
