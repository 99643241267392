import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineBreakPipe } from './pipes/line-break.pipe';
import { FormatTimePipe } from './pipes/format-time.pipe';

@NgModule({
    declarations: [LineBreakPipe, FormatTimePipe],
    imports: [CommonModule],
    exports: [LineBreakPipe, FormatTimePipe],
})
export class UtilitiesModule {}
