import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscriber, Subscription, timer } from 'rxjs';
import { map, switchMap, finalize, takeWhile } from 'rxjs/operators';
import { MarcheAPIService } from '../@core/api/marche-api.service';
import {
    ErrorWrapper,
    ResponseWrapper,
} from '../@shared/models/response-wrapper.model';
import { plainToClassFromExist } from 'class-transformer';
import { RouteExecutor } from '../@core/application-router/route.executor';
import { ApplicationRouterService } from '../@core/application-router/application-router.service';

@Component({
    selector: 'app-payment-processing',
    templateUrl: './payment-processing.component.html',
    styleUrls: ['./payment-processing.component.scss'],
})
export class PaymentProcessingComponent
    extends RouteExecutor
    implements OnInit
{
    public showLoader: boolean = false;
    public timeRemaining$: Observable<string> | undefined;
    public payment$: Observable<unknown> | undefined;
    circumference = 100;
    timerDuration!: number;
    progressBar: number = 100;
    timer: number | undefined;
    trackingId: string = '';
    postUrl = '/credpay/order-status';
    private payment_processing_API = '/payment-processing/:trackingId';
    private timeRemainingSubscription: Subscription | undefined;
    private paymentSubscription: Subscription | undefined;
    data: any;
    errors: ErrorWrapper[] | undefined = [];
    errorCaught = false;

    constructor(
        applicationRouterService: ApplicationRouterService,
        private marcheAPIService: MarcheAPIService,
        private activatedRoute: ActivatedRoute
    ) {
        super(applicationRouterService);
    }

    ngOnInit(): void {
        this.activatedRoute.queryParams.subscribe(params => {
            this.processLink(
                `/fn/internal?page=payment-processing&action=nav-update`
            );
            this.trackingId = params.tracking_id;
            this.payment_processing_API = this.payment_processing_API.replace(
                ':trackingId',
                this.trackingId
            );
            this.paymentProcessingContent();
        });
    }
    ngOnDestroy(): void {
        this.timeRemainingSubscription?.unsubscribe();
        this.paymentSubscription?.unsubscribe();
    }

    private paymentProcessingContent() {
        const subscriber = Subscriber.create<ResponseWrapper<any> | null>(
            value => {
                if (value?.success) {
                    this.data = value?.data;
                    this.timerDuration = this.data?.timer;
                    this.timer = this.data?.counterSecond * 1000;
                    this.makePayment();
                } else {
                    this.errorCaught = true;
                    this.errors = value?.errors;
                    plainToClassFromExist(this.errors, value?.errors);
                    this.paymentSubscription?.unsubscribe();
                }
            },
            error => {},
            () => {}
        );

        this.marcheAPIService
            .getRequest<ResponseWrapper<any> | null>(
                this.payment_processing_API
            )
            .subscribe(subscriber);
    }

    makePayment() {
        this.showLoader = true;
        this.timeRemaining$ = timer(0, 1000).pipe(
            takeWhile(time => time <= this.timerDuration),
            map(time => {
                const minutes = Math.floor((this.timerDuration - time) / 60);
                const seconds = (this.timerDuration - time) % 60;
                this.progressBar =
                    ((this.timerDuration - (minutes * 60 + seconds)) /
                        this.timerDuration) *
                    100;
                return `${minutes < 10 ? '0' : ''}${minutes}:${
                    seconds < 10 ? '0' : ''
                }${seconds}`;
            })
        );

        this.payment$ = timer(0, this.timer).pipe(
            takeWhile(time => time <= this.timerDuration),
            switchMap(() =>
                this.marcheAPIService.postRequest(
                    this.postUrl,
                    {},
                    { tracking_id: this.trackingId }
                )
            )
        );
        this.timeRemainingSubscription = this.timeRemaining$.subscribe(time => {
            if (time === '00:00') {
                finalize(() => {
                    this.payment$?.subscribe();
                });
            }
        });
        this.paymentSubscription = this.payment$.subscribe();
    }
}
