import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RouteExecutionInterface } from './route.executor';
import { MarcheAPIService } from '../api/marche-api.service';
import { ResponseWrapper } from '../../@shared/models/response-wrapper.model';
import { FunctionModel } from '../../@shared/models/function.model';
import * as UrlParse from 'url-parse';
import { Observable, of } from 'rxjs';
import { AnalyticsService } from '../analytics.service';
import { EventQueue } from '../../utils/event-queue';

@Injectable({
    providedIn: 'root',
})
export class ApplicationRouterService implements RouteExecutionInterface {
    public linkStream: EventEmitter<{
        pageId: string;
        query: { [p: string]: string | string[] };

        params?: IParams;
    }> = new EventEmitter<{
        pageId: string;
        query: { [p: string]: string | string[] };
        params?: IParams;
    }>();
    public functionalStream: EventEmitter<{
        id: string;
        query: { [p: string]: string | string[] };

        body?: object;
    }> = new EventEmitter<{
        id: string;
        query: { [p: string]: string | string[] };
        params?: object;
    }>();
    eventQueue = new EventQueue(this.marcheApiService);

    differNavApiCall: EventEmitter<{ navCall: boolean; pageId: string }> =
        new EventEmitter<{ navCall: boolean; pageId: string }>();

    input = [];
    date: any;

    constructor(
        private router: Router,
        private marcheApiService: MarcheAPIService,
        private analyticsService: AnalyticsService
    ) {}

    executeFunctionLink(
        id: string,
        query: { [p: string]: string | string[] },
        body?: object
    ): Observable<any> {
        switch (query?.action) {
            case 'post': {
                return this.marcheApiService.postRequest<
                    ResponseWrapper<FunctionModel>
                >(id, query, body);
            }
            case 'add-to-cart': {
                return this.marcheApiService.postRequest<
                    ResponseWrapper<FunctionModel>
                >(id, query, body);
            }
            case 'get': {
                return this.marcheApiService.getRequest<
                    ResponseWrapper<FunctionModel>
                >(id, query);
            }
            case 'add-on-product': {
                return this.marcheApiService.postRequest<
                    ResponseWrapper<FunctionModel>
                >(id, query);
            }
            case 'ar-model': {
                return this.marcheApiService.getRequest<
                    ResponseWrapper<FunctionModel>
                >(id, query);
            }
            default: {
                this.functionalStream.emit({ id, query, body });
                return of();
            }
        }
    }

    processLink(link: string) {}

    executePageLink(
        pageId: string,
        query: { [p: string]: string | string[] },
        params?: IParams
    ) {
        let route = pageId;

        if (query?.f && query.f !== 'undefined') {
            route += '?f=' + query.f;
        }
        const trId = this.analyticsService.setMapValues(
            params?.tracking || '',
            route
        );

        this.linkStream.emit({ pageId, query, params });
    }

    parseLink(link: string): IRouteParse {
        let routeType: ERouteType;
        if (link.indexOf('/fn/') === -1) {
            routeType = ERouteType.PAGE;
        } else {
            routeType = ERouteType.FUNCTION;
        }

        const url = new UrlParse(link, true);

        if (routeType === ERouteType.FUNCTION) {
            try {
                if (
                    !!url?.query?.tr &&
                    !this.analyticsService.isBot(window.navigator.userAgent)
                ) {
                    this.analyticsService.analyticsTracking(url?.query?.tr);
                }
            } catch (err) {
                console.error(err);
            }

            try {
                const eventBody = {
                    timestamp: new Date().toISOString(),
                    link,
                    screen: window.location.pathname,
                };
                if (!link.includes('/fn/internal')) {
                    this.eventQueue.add(eventBody);
                }
            } catch (e) {
                console.error(e);
            }
        }

        return {
            id: url.pathname,
            type: routeType,
            query: url.query as { [p: string]: string | string[] },
            params: {
                refresh: url.query?.refresh,
                transient: url.query?.transient,
                transition: url.query?.transition,
                popup: url.query?.popup,
                tracking: url?.query?.tr,
            },
        };
    }
}

export interface IRouteParse {
    type: ERouteType;
    id: string;
    query: { [p: string]: string | string[] };
    params?: IParams | null;
}

export enum ERouteType {
    FUNCTION,
    PAGE,
}

export interface IParams {
    refresh: string | undefined;
    transient: string | undefined;
    transition: string | undefined;
    popup: string | undefined;
    tracking?: string | undefined;
}
