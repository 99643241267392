<div
    [ngStyle.gt-xs]="{ padding: '46px 36px' }"
    [ngStyle.xs]="{ paddingTop: '25px', paddingBottom: '25px' }"
>
    <div fxLayout="column">
        <span
            ngClass.gt-xs="popup-title__desktop"
            ngClass.xs="popup-title__mobile"
            [innerHTML]="popupData?.title?.text | lineBreak"
        ></span>
        <span
            ngClass.gt-xs="popup-subtitle__desktop"
            ngClass.xs="popup-subtitle__mobile"
        >
            {{ popupData?.subtitle?.text }}
        </span>
        <div fxLayout="row" fxLayoutGap.gt-xs="12px" fxLayoutGap.xs="8px">
            <div
                class="secondary-button"
                fxLayoutAlign="center center"
                ngClass.xs="secondary-button__mobile"
                (click)="processLink(popupData?.secondaryButton?.button?.link)"
                ><span>{{
                    popupData?.secondaryButton?.button?.text
                }}</span></div
            >
            <button
                class="primary-button"
                ngClass.xs="primary-button__mobile"
                mat-flat-button
                color="primary"
                (click)="primaryPreferenceButton()"
                >{{ popupData?.primaryButton?.button?.text }}</button
            >
        </div>
    </div>
</div>
