import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { DropDownPageComponent } from './dropdown-page/dropdown-page.component';
import { ImageSrcDirective } from './directives/image-src.directive';
import { DropdownShimmerComponent } from './dropdown-shimmer/dropdown-shimmer.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ViewPortVisibilityNotifier } from './directives/visibility-notifier.directive';
import { QueryParamsFromLinkPipe } from './pipes/queryParamsFromLink.pipe';
import { PageIdFromLinkPipe } from './pipes/pageIdFromLink.pipe';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { RouterModule } from '@angular/router';
import { PipeSafePipeURL } from './pipes/sanitizeURL.pipe';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { ImageSrcV2Directive } from './directives/image-src-v2.directive';
import { UtilitiesModule } from '../utilities/utilities.module';

@NgModule({
    declarations: [
        DropDownPageComponent,
        ImageSrcDirective,
        DropdownShimmerComponent,
        ErrorPageComponent,
        ViewPortVisibilityNotifier,
        QueryParamsFromLinkPipe,
        PageIdFromLinkPipe,
        PipeSafePipeURL,
        ImageSrcV2Directive,
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        MatTabsModule,
        MatCardModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatInputModule,
        FormsModule,
        MatSelectModule,
        MatIconModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        RouterModule,
        UtilitiesModule,
    ],
    exports: [
        DropDownPageComponent,
        ErrorPageComponent,
        ImageSrcDirective,
        ViewPortVisibilityNotifier,
        QueryParamsFromLinkPipe,
        PageIdFromLinkPipe,
        FlexLayoutModule,
        MatTabsModule,
        MatCardModule,
        MatButtonModule,
        ReactiveFormsModule,
        MatInputModule,
        FormsModule,
        MatSelectModule,
        MatCheckboxModule,
        MatIconModule,
        MatProgressSpinnerModule,
        PipeSafePipeURL,
        ImageSrcV2Directive,
    ],
})
export class SharedModule {}
