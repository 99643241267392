import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { ResponseWrapper } from '../@shared/models/response-wrapper.model';
import { MarcheAPIService } from './api/marche-api.service';
import { ApplicationRouterService } from './application-router/application-router.service';
import { RouteExecutor } from './application-router/route.executor';
import { MixPanelService } from './mixpanel.service';

@Injectable({ providedIn: 'root' })
export class userIdentifyService {
    constructor(
        private marcheAPIService: MarcheAPIService,
        private mixPanelService: MixPanelService
    ) {}
    static readonly Identify = '/fn/userinfo';

    userIdentify() {
        const subscriber = Subscriber.create<ResponseWrapper<any> | null>(
            value => {
                if (value?.success) {
                    this.mixPanelService.peopleSet({
                        $name: value?.data?.name,
                        $email: value?.data?.email,
                        $phone: value?.data?.phone,
                        $birthday: value?.data?.birthday,
                    });
                } else {
                }
            },
            error => {},
            () => {}
        );

        this.marcheAPIService
            .getRequest<ResponseWrapper<any> | null>(
                userIdentifyService.Identify
            )
            .subscribe(subscriber);
    }
}
