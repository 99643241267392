import {
    Directive,
    ElementRef,
    HostBinding,
    Inject,
    Input,
    OnChanges,
    PLATFORM_ID,
    SimpleChanges,
} from '@angular/core';

@Directive({
    selector: '[appImageCDN]',
})
export class ImageSrcDirective implements OnChanges {
    constructor(
        private el: ElementRef,
        @Inject(PLATFORM_ID) private platformId: object
    ) {
        this.el.nativeElement.setAttribute('loading', 'lazy');
        this.dpr = Math.floor(Math.max(window.devicePixelRatio || 1, 1));
    }

    @HostBinding('attr.src') srcAttr?: string | null;
    @HostBinding('attr.alt') altAttr?: string | null;

    @Input() readonly src?: string | null;
    @Input() width: number | null = null;
    @Input() height: number | null = null;
    @Input() alt: string | null = null;

    private readonly dpr: number;

    private static addImageKitParams(
        url: string,
        width: number | null,
        height: number | null,
        dpr: number
    ) {
        const transformations: string[] = [];
        if (width) {
            transformations.push(`w-${width}`);
        }
        if (height) {
            transformations.push(`h-${height}`);
        }
        transformations.push(`dpr-${dpr}`);
        return `${url},${transformations.join(',')}`;
    }

    private static getImageName(src: string) {
        const lastSlashIndex = src.lastIndexOf('/');
        const lastIndex = src.lastIndexOf('?');
        return src.substring(lastSlashIndex + 1, lastIndex) || '';
    }

    ngOnChanges(changes: SimpleChanges) {
        this.width = this.el.nativeElement.offsetWidth;
        this.height = this.el.nativeElement.offsetHeight;
        this.updateSrcImagePath();
    }

    private updateSrcImagePath() {
        let quality = 'q-60';
        if (this.src && this.src !== '') {
            this.srcAttr = ImageSrcDirective.addImageKitParams(
                this.src,
                this.width,
                this.height,
                this.dpr
            );
            if (this.dpr > 1) {
                this.srcAttr = this.srcAttr + ',' + quality;
            }
            if (this.alt === null) {
                this.altAttr = ImageSrcDirective.getImageName(this.src);
            } else {
                this.altAttr = this.alt;
            }
        }
    }
}
