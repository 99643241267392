import { CText } from '../@shared/models/text.model';

export class CPData {
    readonly layout: string;
    readonly text: string;
    readonly feed: Feed[];
    readonly banner: Banner | null;
    readonly timerStrip?: any;

    tabs: Array<{ text: string; link: string; active: boolean }>;
    items: Array<Item>;

    constructor(banner: Banner | null = null, timerStrip?: any) {
        this.layout = '';
        this.text = '';
        this.feed = [];
        this.tabs = [];
        this.items = [];
        this.banner = banner;
        this.timerStrip = timerStrip;
    }

    // changeActiveTab(tabIndex: number) {
    //     this.tabs[tabIndex].active = true;
    //     this.updateItemsList();
    // }
    //
    // private updateItemsList() {
    //     this.items = [];
    //     for (const item of this.feed) {
    //         if (item.active) {
    //             this.items = item.items;
    //         }
    //     }
    // }

    isHeaderVisible() {
        return this.text !== '';
    }

    areTabsVisible() {
        return this.tabs.length > 1;
    }
}

export interface Feed {
    link: string;
    active: boolean;
    text: string;
    items: Item[];
}

export interface Item {
    link: string;
    image: string;
    title: CText;
    background: { color: string };
    hover?: { link: string };
    newLaunch?: boolean;
}
export class Banner {
    image: string;
    scrim?: { color: string; alpha: string };
    video?: string;
    link: string;

    constructor() {
        this.image = '';
        this.video = '';
        this.link = '';
        this.scrim = {
            color: '',
            alpha: '',
        };
    }
}
