<div
    class="error-page"
    [ngStyle]="{
        height: !asFullPage ? 'auto' : '',
        minHeight: asFullPage ? 'calc(100vh - 82px)' : '0',
        background:
            error[0].layoutType === 'default' ? 'transparent' : '#F7F7F7'
    }"
    fxLayout="column"
    fxLayoutAlign="center center"
>
    <div
        fxLayout="column"
        fxLayoutAlign.gt-xs="center"
        style="width: 100%"
        *ngFor="let err of error"
        [ngStyle.gt-xs]="{
            height: asFullPage ? 'calc( 100vh - 82px )' : 'auto'
        }"
    >
        <div
            *ngIf="err?.layoutType === 'default'"
            fxLayout="column"
            style="height: 100%"
            fxLayoutAlign="center center"
        >
            <img
                *ngIf="err?.image"
                src="{{ err?.image }}"
                style="width: 60px; height: 60px; margin-bottom: 20px"
                [ngStyle.gt-xs]="{ width: '80px', height: '80px' }"
            />
            <p
                class="error-page__heading"
                style="margin-bottom: 10px"
                [innerHTML]="err?.title | lineBreak"
            >
            </p>
            <span
                *ngIf="err?.description"
                class="error-page__description__text"
                style="color: #707070"
                [style]="{ marginBottom: err.code ? '10px' : '16px' }"
            >
                {{ err?.description }}
            </span>
            <span
                *ngIf="err?.code"
                class="error-page__description__text"
                style="color: #707070; margin-bottom: 16px"
            >
                Error Code: {{ err?.code }}
            </span>
            <button
                color="primary"
                style="
                    border-radius: 0;
                    width: auto;
                    padding-left: 60px;
                    padding-right: 60px;
                "
                class="call-to-action-button"
                mat-raised-button
                (click)="processLink(err.cta.link)"
            >
                {{ err?.cta?.text }}
            </button>
        </div>

        <div
            *ngIf="err?.layoutType === '404-page'"
            fxLayout.gt-xs="row"
            fxLayout="column-reverse"
        >
            <div
                fxFlex.gt-xs="0 0 50%"
                [ngStyle.gt-xs]="{ paddingLeft: '12vw' }"
            >
                <h2
                    class="error-title"
                    [ngStyle.xs]="{
                        fontSize: '40px',
                        marginBottom: '32px',
                        whiteSpace: 'break-spaces'
                    }"
                    >{{ err?.title }}</h2
                >
                <p
                    class="error-subtitle"
                    [ngStyle.xs]="{ fontSize: '14px', margin: '0 0 13px 0' }"
                    >{{ err?.subTitle?.text }}</p
                >
                <div
                    class="top-links"
                    fxLayoutGap.gt-xs="9px"
                    fxLayoutGap="11px"
                    fxLayout="column"
                >
                    <span
                        *ngFor="let button of err.buttons"
                        class="top-links__text"
                        fxLayout="row"
                        fxLayoutAlign="none center"
                        (click)="processLink(button?.link)"
                    >
                        <img
                            style="width: 14px; height: 14px; margin-right: 5px"
                            src="{{ button?.icon }}"
                        /><p style="margin: 0">{{ button?.text }}</p>
                    </span>
                </div>
            </div>
            <div
                fxFlex.gt-xs="0 0 50%"
                class="error-404"
                fxLayout="column"
                fxLayoutAlign.gt-xs="center"
                fxLayoutAlign="center center"
                ngClass.xs="error-404-mob"
            >
                <picture
                    class="error-404__container"
                    ngClass.xs="error-404-mob__container"
                >
                    <img
                        class="error-404__container__image"
                        ngClass.xs="error-404-mob__container__image"
                        src="{{ err?.image }}"
                        appImageCDN
                    />
                </picture>
            </div>
        </div>
    </div>
</div>

<!--

<div class="product-page">
    <img
        fxShow
        fxShow.gt-xs="false"
        (click)="backButton()"
        class="back-button"
        style="position: inherit; margin: 0; align-self: flex-start;"
        src="https://images.dailyobjects.com/icons/arrow.png"
    />
    <img
        (click)="dialogRef.close()"
        *ngIf="isPagePopup"
        style="margin-left: 100%; cursor: pointer;"
        src="https://images.dailyobjects.com/icons/cross.png" class="icon"
    />
    <h1
        *ngIf="!asHTMLTag"
        class="product-page__heading"
        fxLayout="row"
        fxLayoutAlign.gt-xs="center"
    >
        {{ pageData?.title?.text }}
    </h1>
    <div class="product-page__description" *ngFor="let info of pageData?.feed">
        <h2 class="product-page__description__heading">
            {{ info?.title?.text }}
        </h2>
        <h3 class="product-page__description__subheading">
            {{ info?.subtitle?.text }}
        </h3>
        <p
            [ngStyle.gt-xs]="{
                color: staticPage === true ? '#909090' : 'black'
            }"
            class="product-page__description__text"
            *ngFor="let text of info?.texts; let i = index"
            [innerHTML]="stylize(text, info.hyperlink, i)"
        ></p>
    </div>
</div>

-->
