import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ErrorWrapper } from '../models/response-wrapper.model';
import { DropdownPageData } from './dropdown-page.data';
import { DropdownPageService } from './dropdown-page.service';

@Component({
    selector: 'app-dropdown',
    templateUrl: './dropdown-page.component.html',
    styleUrls: ['./dropdown-page.component.scss'],
})
export class DropDownPageComponent implements OnChanges {
    private readonly GHOST_SHOW_STATE = [1, 2, 3, 4, 5, 6, 7];
    private readonly GHOST_HIDE_STATE = [];
    ghosts: number[] = [];
    cache = new Map<string, boolean>();
    data: DropdownPageData = new DropdownPageData();
    @Input() parameters: { [p: string]: string | string[] } = {};
    @Output() closeDropDown: EventEmitter<any> = new EventEmitter();
    errors: ErrorWrapper[] = [];
    feedBasedFlex: { [p: string]: string } = {
        '1-col': '1 1 15%',
        '2-col': '1 1 25%',
        '1-col-cases': '1 1 10%',
        '2-row': '1 1 40%',
        '1-col-bold': '1 1 15%',
        '1-separate-col': '0 0 50%',
        'image-with-feed': '0 0 14.28%',
        'collection-image-with-feed': '0 0 15.28%',
        'image-with-title': '0 0 42.78%',
    };

    constructor(private dropdownService: DropdownPageService) {
        this.ghosts = this.GHOST_SHOW_STATE;
    }

    ngOnChanges(changes: SimpleChanges) {
        const key = this.parameters?.f?.toString();
        console.log(changes);
        const ghostsShown = this.showGhostsIfRequired(key);
        if (ghostsShown) {
            this.data = new DropdownPageData();
        }
        if (
            changes.parameters.previousValue !==
                changes.parameters.currentValue &&
            !!key
        ) {
            this.dropdownService.getData(this.parameters).subscribe(
                value => {
                    if (value.success) {
                        this.data = value.data;
                        this.cache.set(key, true);
                    } else {
                        // TODO Handle Error
                    }
                },
                error => {},
                () => {
                    this.ghosts = this.GHOST_HIDE_STATE;
                }
            );
        }
    }

    private showGhostsIfRequired(key: string): boolean {
        if (this.cache.has(key)) {
            this.ghosts = this.GHOST_HIDE_STATE;
            return false;
        } else {
            this.ghosts = this.GHOST_SHOW_STATE;
            return true;
        }
    }

    itemClicked(value: string) {
        this.closeDropDown.emit(value);
    }

    processLink(link: string) {
        this.dropdownService.processLink(link);
    }
}
