import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ApplicationRouterService } from './application-router/application-router.service';
import { RouteExecutor } from './application-router/route.executor';
import * as mixpanel from 'mixpanel-browser';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class MixPanelService {
    constructor() {
        mixpanel.init(environment.mixpanel.token);
    }

    identify(id: string) {
        mixpanel.identify(id);
    }

    peopleSet(properties: any) {
        mixpanel.people.set(properties);
    }

    track(event: string, properties?: any) {
        mixpanel.track(event, properties);
    }

    increment(event: string, properties?: any) {
        mixpanel.people.increment(event, properties);
    }

    reset() {
        mixpanel.reset();
    }
}
