import { NgModule } from '@angular/core';
import { SharedModule } from '../@shared/shared.module';
import { CommonModule } from '@angular/common';
import { SharedExceptMainModule } from '../@shared-except-main/shared-except-main.module';
import { InternationalPopupComponent } from './international-popup.component';
import { UtilitiesModule } from '../utilities/utilities.module';

@NgModule({
    declarations: [InternationalPopupComponent],
    imports: [
        CommonModule,
        SharedModule,
        SharedExceptMainModule,
        UtilitiesModule,
    ],
})
export class InternationalPopupModule {}
